export const products = [
  {
    id: "price_1NNLdSFVmYi2pzElEmN8HoBU",
    title: "Islas Marietas",
    price: 399,
  },
  {
    id: "price_1NNLaAFVmYi2pzElr7GDO0GD",
    title: "Playa Esmeralda",
    price: 299,
  },
  {
    id: "price_1NNLcWFVmYi2pzElywaDG86P",
    title: "Yelapa",
    price: 599,
  },
];

export const getProductData = (id) => {
  let productData = products.find((product) => product.id === id);

  if (productData === undefined) {
    console.log("product data does not exist with ID:" + id);
    return;
  }
  return productData;
};
