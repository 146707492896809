import { useContext, useState } from 'react';
import { Button, Modal, Navbar } from 'react-bootstrap';
import { CartContext } from '../CartContex';
import { CartProduct } from './CartProduct';

export const NavbarComponent = () => {
  const cart = useContext(CartContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkout = async () => {
    await fetch('https://viajeros-x-angelsol299.onrender.com/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: cart.items }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.url) {
          window.location.assign(res.url);
        }
      });
  };

  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return (
    <>
      <Navbar
        style={{ padding: '20px' }}
        expand='sm'
        bg='primary'
        variant='dark'
      >
        <Navbar.Brand href='/'>Viajeros X</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end' bg='primary'>
          <Button variant='light' onClick={handleShow}>
            Cart {productsCount} Items
          </Button>
        </Navbar.Collapse>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Shopping card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsCount > 0 ? (
            <>
              <p>Items in your cart:</p>
              {cart.items.map((currentProduct, idx) => (
                <CartProduct
                  key={idx}
                  id={currentProduct.id}
                  quantity={currentProduct.quantity}
                />
              ))}

              {/* <h1>Total: {cart.getTotalCost().toFixed(2)}</h1> */}
              <Button variant={'success'} onClick={checkout}>
                Purchase items!
              </Button>
            </>
          ) : (
            <h1>There are no items in your cart!</h1>
          )}
          <h1>this is the body</h1>
        </Modal.Body>
      </Modal>
    </>
  );
};
