import { useEffect, useRef } from "react";
import Beach from "../../assets/beach-1.jpg";
import styles from "./ProductDetails.module.css";
import Image from "react-bootstrap/Image";
import { SearchBar } from "../SearchBar";
import moment from "moment";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { selectItem, selectTotalPayment } from "../../state/product/selectors";
import { useNavigate } from "react-router-dom";

export const ProductDetails = () => {
  const navigate = useNavigate();
  const paymentRef = useRef(null);
  const paramsState = useSelector(selectItem);
  const totalPayment = useSelector(selectTotalPayment);
  const pickupTime = useSelector(selectItem).pickupTime;

  useEffect(() => {
    if (totalPayment && pickupTime) {
      paymentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [totalPayment, pickupTime]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pickupDate = moment(paramsState.date).format("LL");

  const toPayment = () => navigate("/checkout");

  const enableSubmitButton =
    paramsState.date && paramsState.numPeople && paramsState.date && pickupTime;

  return (
    <div>
      <div className={styles.imageContainer}>
        <Image
          className={styles.responsiveImage}
          src={Beach}
          alt="beach"
          fluid
        />
        {paramsState && (
          <>
            <div className={styles.textContainerImage}>
              <h2 className={styles.text}>Viaje a {paramsState.place}</h2>
              <h2 className={styles.text}>
                Precio <strong>${paramsState.price}MXN</strong>
              </h2>
            </div>
            <div className={styles.searchBarContainer}>
              <SearchBar
                paramsObject={paramsState}
                chosenPlace={paramsState.place}
                pageName="productDetails"
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.dividerLine} />
      {paramsState && (
        <>
          <p className={styles.textDescription}>
            {paramsState.description.title}
          </p>
          <div className={styles.lowerPart}>
            <div className={styles.textContainer}>
              <p className={styles.subtitle}>
                {paramsState.description.subtitle1}
              </p>
              <p> {paramsState.description.paragraph1}</p>
              <p className={styles.subtitle}>
                {paramsState.description.subtitle2}
              </p>
              <p> {paramsState.description.paragraph2}</p>
            </div>
            <Card ref={paymentRef} className={styles.bookingDetails}>
              <p className={styles.textAmountTitle}>Reservar</p>
              <div className={styles.textRow}>
                <p>Lugar:</p>
                <strong>{paramsState.place}</strong>
              </div>
              <div className={styles.textRow}>
                <p>Fecha:</p>
                <p>{pickupDate}</p>
              </div>
              <div className={styles.textRow}>
                <p>Hora de salida</p>
                <p>{paramsState.pickupTime}</p>
              </div>
              <div className={styles.textRow}>
                <p>Numero de personas</p>
                <p>{paramsState.numPeople} adultos</p>
              </div>
              <div className={styles.textRow}>
                <p>Precio por persona</p>
                <p>${paramsState.price}MXN</p>
              </div>
              <hr />
              <p className={styles.totalAmountText}>
                Total: ${totalPayment}MXN
              </p>
              <Button
                disabled={!enableSubmitButton}
                onClick={toPayment}
                variant="primary"
              >
                Continuar
              </Button>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};
