import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    item: {
      place: '',
      numPeople: 0,
      pickupTime: '',
      date: '',
      price: 0,
      productID: '',
    },
    items: [],
  },

  reducers: {
    getProduct: (state, { payload }) => {
      state.items = payload;
    },
    updateProduct: (state, { payload }) => {
      state.item = payload;
    },
  },
});

export const { updateProduct, getProduct } = productSlice.actions;

export default productSlice.reducer;
