import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavbarComponent } from "./components/Navbar";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Store } from "./pages/store";
import { Success } from "./pages/success";
import { Cancel } from "./pages/cancel";
import { Checkout } from "./pages/checkout";
import { CartProvider } from "./CartContex";
import { ProductDetails, Footer } from "./components";

function App() {
  return (
    <CartProvider>
      <NavbarComponent />
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<Store />}></Route>
          <Route index path="/success" element={<Success />}></Route>
          <Route index path="/cancel" element={<Cancel />}></Route>
          <Route
            index
            path="/productDetails/:params"
            element={<ProductDetails />}
          ></Route>
          <Route index path="/checkout" element={<Checkout />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </CartProvider>
  );
}

export default App;
