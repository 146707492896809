import { Card } from "react-bootstrap";
import { CartContext } from "../../CartContex";
import { useContext } from "react";
import { AddCart } from "./AddCart";
import styles from "./ProductCard.module.css";

export const ProductCard = ({ product }) => {
  const cart = useContext(CartContext);

  const productQuantity = cart.getProductQuantity(product.id);

  return (
    <Card className="flex-grow-1">
      <Card.Body className={styles.cardBody}>
        <Card.Title>{product.tourName}</Card.Title>
        <Card.Text>{`Precio: $${product.price}`}</Card.Text>
        <Card.Text>{`${product.description.subtitle1}`}</Card.Text>
        <Card.Text>{`${product.duration} hrs`}</Card.Text>
        <AddCart
          productQuantity={productQuantity}
          cart={cart}
          product={product}
        />
      </Card.Body>
    </Card>
  );
};
