import styles from "./cancel.module.css";
import Warning from "../../assets/warning.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export const Cancel = () => {
  const navigate = useNavigate();

  const toMainPage = () => navigate("/");
  return (
    <div className={styles.mainContainer}>
      <div className={styles.upperContainer}>
        <h1 className={styles.textTitle}>Su transacción ha sido cancelada</h1>
        <img className={styles.img} src={Warning} alt="" />
      </div>
      <p className={styles.text}>Por favor intente de nuevo</p>
      <Button className={styles.buttonContainer} onClick={toMainPage}>
        Continuar
      </Button>
    </div>
  );
};
