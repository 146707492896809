import Modal from "react-bootstrap/Modal";
import styles from "./transactionModal.module.css";
import Spinner from "react-bootstrap/Spinner";

export const TransactionModal = ({ isLoading }) => {
  return (
    <Modal show={isLoading} backdrop="static" keyboard={false}>
      <div className={styles.container}>
        Por favor espere, estamos procesando su petición de pago.
        <br />
        Este proceso puede demorar unos segundos más.
        <br />
        <br />
        <Spinner animation="grow" variant="primary" />
        <br />
        En proceso...
      </div>
    </Modal>
  );
};
