import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { selectItem, selectTotalPayment } from '../../state/product/selectors';
import { TransactionModal } from './TransactionModal';
import styles from './checkout.module.css';

import Card from 'react-bootstrap/Card';

export const Checkout = () => {
  const totalPayment = useSelector(selectTotalPayment);
  const paramsState = useSelector(selectItem);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    hotelOrAddress: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorDifferentEmails, setErrorDifferentEmails] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  const pickupDate = moment(paramsState.date).format('LL');

  const product = {
    price: paramsState.productID,
    quantity: paramsState.numPeople,
    fullName: formData.fullName,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    hotelOrAddress: formData.hotelOrAddress,
    tourName: paramsState.place,
    priceItem: paramsState.price,
  };

  const checkout = async () => {
    console.log({ product });
    if (formData.email === formData.confirmEmail) {
      setIsLoading(true);
      setErrorDifferentEmails(false);
      await fetch('https://viajeros-x-angelsol299.onrender.com/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items: [product] }),
      })
        .then((response) => {
          setIsLoading(false);
          return response.json();
        })
        .then((response) => {
          setIsLoading(false);
          if (response.url) {
            window.location.assign(response.url);
          }
        });
    } else {
      setErrorDifferentEmails(true);
    }
  };

  const isFormEnabled =
    formData.fullName &&
    formData.email &&
    formData.confirmEmail &&
    formData.phoneNumber &&
    formData.hotelOrAddress;

  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.upperPart}>
          <Form.Group controlId='fullName' className={styles.formElement}>
            <Form.Label>Nombre completo</Form.Label>
            <Form.Control
              type='text'
              name='fullName'
              value={formData.fullName}
              onChange={handleChange}
              placeholder='Introduzca su nombre completo'
              required
            />
          </Form.Group>

          <Form.Group controlId='email' className={styles.formElement}>
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder='La confirmación será enviada a esta dirección'
              required
            />
          </Form.Group>

          <Form.Group controlId='confirmEmail' className={styles.formElement}>
            <Form.Label>Confirmar Correo electrónico</Form.Label>
            <Form.Control
              type='email'
              name='confirmEmail'
              value={formData.confirmEmail}
              onChange={handleChange}
              placeholder='La confirmación será enviada a esta dirección'
              required
            />
          </Form.Group>

          <Form.Group controlId='phoneNumber' className={styles.formElement}>
            <Form.Label>Número de teléfono</Form.Label>
            <Form.Control
              type='tel'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder='Introduce tu número de teléfono'
              required
            />
          </Form.Group>

          <Form.Group controlId='hotelOrAddress' className={styles.formElement}>
            <Form.Label>Hotel o dirección</Form.Label>
            <Form.Control
              type='text'
              name='hotelOrAddress'
              value={formData.hotelOrAddress}
              onChange={handleChange}
              placeholder='Introduce tu hotel o dirección'
              required
            />
          </Form.Group>
        </div>
        <div className={styles.bookingContainer}>
          <Card className={styles.bookingDetails}>
            <strong className={styles.textAmountTitle}>Reserva</strong>
            <div className={styles.textRow}>
              <p>Destino:</p>
              <strong>{paramsState.place}</strong>
            </div>
            <div className={styles.textRow}>
              <p>Fecha:</p>
              <p>{pickupDate}</p>
            </div>
            <div className={styles.textRow}>
              <p>Hora de salida</p>
              <p>{paramsState.pickupTime}</p>
            </div>
            <div className={styles.textRow}>
              <p>Numero de personas</p>
              <p>{paramsState.numPeople} adultos</p>
            </div>
            <div className={styles.textRow}>
              <p>Precio por persona</p>
              <p>${paramsState.price}MXN</p>
            </div>
            <hr />
            <strong className={styles.totalAmountText}>
              Total: ${totalPayment}MXN
            </strong>
            {errorDifferentEmails && (
              <p className={styles.emailMatchError}>
                El correo electrónico confirmado no coincide, revise nuevamente
                por favor
              </p>
            )}
          </Card>

          <Button
            disabled={!isFormEnabled}
            onClick={checkout}
            variant='primary'
            size='lg'
            style={{ width: '100%' }}
          >
            {isLoading ? (
              <Spinner animation='border' variant='light' size='sm' />
            ) : (
              'Pagar'
            )}
          </Button>
        </div>
        <TransactionModal isLoading={isLoading} />
      </Form>
    </div>
  );
};
