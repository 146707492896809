import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <Container className={styles.subContainer}>
        <Row>
          <Col className={styles.text} md={6}>
            <p>© 2023 Your Website. All rights reserved.</p>
          </Col>
          <Col md={6} className={styles.text}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/" className={styles.text}>
                  Home
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/about" className={styles.text}>
                  About
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/contact" className={styles.text}>
                  Contact
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
