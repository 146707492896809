import { Row, Col, Container } from "react-bootstrap";
import { ProductCard } from "../components/ProductCard/ProductCard";
import { useEffect } from "react";
import { SearchBarElement } from "../components/SearchBarElement";
import { useDispatch, useSelector } from "react-redux";
import { getProduct, updateProduct } from "../state/product/slice";
import styles from "./store.module.css";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../services/queries";
import { selectItems } from "../state/product/selectors";

export const Store = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectItems);

  const navigate = useNavigate();

  const handleClick = (product) => {
    const params = {
      place: product.tourName,
      numPeople: null,
      date: "",
      description: product.description,
      price: product.price,
      productID: product.productID,
    };

    dispatch(updateProduct(params));
    navigate(`/productDetails/:${product.tourName}`);
  };

  useEffect(() => {
    const fetchDataAndDispatch = async () => {
      const data = await fetchData();
      dispatch(getProduct(data));
    };

    if (products.length === 0) {
      fetchDataAndDispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchBarElement />
      <Container className="p-3">
        <h1 align="center" className="p-4">
          Elige uno de nuestros destinos más populares
        </h1>
        <div className={styles.cardsContainer}>
          {products && products.length > 0 && (
            <Row xs={1} md={3} className="g-4">
              {products.map((product, idx) => {
                return (
                  <Col
                    className={styles.columnWrapper}
                    key={product.tourName}
                    onClick={() => handleClick(product)}
                  >
                    <ProductCard product={product} />
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </Container>
    </>
  );
};
