import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import greenCheck from '../../assets/green-check.png';
import styles from './success.module.css';

export const Success = () => {
  const navigate = useNavigate();

  const toMainPage = () => navigate('/');

  return (
    <div className={styles.mainContainer}>
      <div className={styles.upperContainer}>
        <h1>Su pago ha sido recibido</h1>

        <img
          className={styles.img}
          src={greenCheck}
          alt='green check'
          width='100%'
          height='100%'
        />
      </div>
      <p>
        En breve recibirá un correo electrónico con toda la información
        correspondiente al tour que reservó.
      </p>

      <Button className={styles.buttonContainer} onClick={toMainPage}>
        Continuar
      </Button>
    </div>
  );
};
