import Button from "react-bootstrap/Button";
import { CartContext } from "../CartContex";
import { useContext } from "react";
import { getProductData } from "../productStore";

export const CartProduct = ({ id, quantity }) => {
  const { deleteFromCart } = useContext(CartContext);

  const productData = getProductData(id);

  return (
    <>
      <h3>{productData.title}</h3>
      <p>{quantity} total</p>
      <p>{(quantity * productData.price).toFixed(2)}</p>
      <Button size={"sm"} onClick={() => deleteFromCart(id)}>
        Remove
      </Button>
    </>
  );
};
