import { db } from "./firebase";
import { getDocs, collection } from "firebase/firestore";

export const fetchData = async () => {
  try {
    const docRef = await getDocs(collection(db, "tours"));

    const newData = docRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    return newData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
