import { SearchBar } from "../SearchBar";

import styles from "./Video.module.css";

import dronie from "../../assets/pvr-drone.png";

export const SearchBarElement = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>Reserva un tour</div>
      <SearchBar />
      <img
        className={styles.imageDesktopContainer}
        src={dronie}
        alt="corona pvr"
      />
      <div className={styles.lowerText}>
        Te llevamos a playas que sólo los locales conocen
      </div>
    </div>
  );
};
