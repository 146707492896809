export const getAvailableDates = (tours, place, state, setState) => {
  const desiredTour = tours?.find((tour) => tour.tourName === place.value);

  if (desiredTour && desiredTour.availableDates) {
    const availableDatesToBook = desiredTour.availableDates;

    let acc = [];

    availableDatesToBook.forEach((element) => {
      const milliseconds = element.seconds * 1000;
      const date = new Date(milliseconds);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const convertedDate = `${year}-${month}-${day}`;
      acc.push(convertedDate);
    });
    setState({
      ...state,
      availableDates: acc,
      description: desiredTour.description,
      price: desiredTour.price,
      productID: desiredTour.productID,
    });
  }

  console.log("inner State II: ", state);
};

export const filterDate = (date, totalAvailableDates) => {
  const currentDate = new Date();
  const dateString = date.toISOString().split("T")[0];
  return (
    totalAvailableDates.includes(dateString) &&
    new Date(dateString) >= currentDate
  );
};

const timeTable = [
  "08:00 am",
  "08:30 am",
  "09:00 am",
  "09:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
];

export const timeTableArray = [
  ...timeTable.map((time) => ({
    value: time,
    label: time,
  })),
];
