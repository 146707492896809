import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import styles from './SearchBar.module.css';
import { filterDate, getAvailableDates, timeTableArray } from './utils';

import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectItem, selectItems } from '../../state/product/selectors';
import { updateProduct } from '../../state/product/slice';

//problem is that description is not updating on utils with the setter

export const SearchBar = ({ chosenPlace, pageName }) => {
  const dispatch = useDispatch();
  const product = useSelector(selectItem);
  const tours = useSelector(selectItems);
  const navigate = useNavigate();

  const isProductDetails = pageName === 'productDetails';

  const initialState = {
    place: chosenPlace ? { value: chosenPlace, label: chosenPlace } : null,

    numPeople: isProductDetails ? product.numPeople : null,
    date: isProductDetails ? product.date : null,
    pickupTime: {
      value: product.pickupTime ?? '',
      label: product.pickupTime ?? '',
    },
    availableDates: [],
    description: isProductDetails ? product.description : {},
    id: '',
    price: isProductDetails ? product.price : 0,
    productID: '',
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (state.place) {
      getAvailableDates(tours, state.place, state, setState);
      // Perform additional actions based on the selected option
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPlace, state.place]);

  useEffect(() => {
    setState({
      ...state,
      pickupTime: {
        value: product.pickupTime ?? '',
        label: product.pickupTime ?? '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tours || tours.length === 0) {
    return (
      <div className={styles.loaderContainer}>
        <Spinner animation='grow' variant='light' />
      </div>
    );
  }

  const totalAvailableDates = state.availableDates ?? [];

  const handleSelectPlaceChange = (selectedOption) => {
    setState({ ...state, place: selectedOption });
  };

  const places = [
    ...tours.map((tour) => ({
      value: tour.tourName,
      label: tour.tourName,
    })),
  ];

  const handleNumPeopleChange = (event) => {
    setState({ ...state, numPeople: Number(event.target.value) });
  };

  const handleDateChange = (date) => {
    setState({ ...state, date });
  };

  const handlePickupTime = (selectedOption) => {
    setState({ ...state, pickupTime: selectedOption });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      padding: '0 30px',
      boxShadow: state.isFocused ? '0 0 0 2px #aaa' : 'none',
      '&:hover': {
        border: '1px solid #aaa',
      },
      width: '275px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      maxWidth: '275px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ddd' : 'transparent',
      '&:hover': {
        backgroundColor: '#eee',
      },
    }),
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (pageName === 'productDetails') {
      // postBooking({
      //   place: state.place.value,
      //   numPeople: state.numPeople,
      //   date: state.date,
      //   pickupTime: state.pickupTime.value,
      // });

      const params = {
        place: state.place.value,
        numPeople: state.numPeople,
        date: state.date,
        description: state.description,
        price: state.price,
        pickupTime: state.pickupTime.value,
        productID: state.productID,
      };

      dispatch(updateProduct(params));
    } else {
      const params = {
        place: state.place.value,
        numPeople: state.numPeople,
        date: state.date,
        description: state.description,
        price: state.price,
        productID: state.productID,
      };

      dispatch(updateProduct(params));
      navigate(`/productDetails/:${state.place.value}`);
    }
  };

  const mainContainer =
    pageName === 'productDetails'
      ? styles.productDetailsContainer
      : styles.container;

  const enableSubmitButtonMainScreen =
    state?.place?.value && state?.numPeople && state?.date;

  const enableSubmitButtonProductDetailsScreen =
    enableSubmitButtonMainScreen && state.pickupTime.value;

  console.log('PICKUP TIME: ', state.pickupTime);

  return (
    <div className={styles.main}>
      <Form className={mainContainer}>
        <Form.Group>
          <Select
            styles={customStyles}
            className={styles.controller}
            value={state.place}
            onChange={handleSelectPlaceChange}
            options={places}
            placeholder='Selecciona una playa'
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            className={styles.controller}
            type='number'
            value={state.numPeople}
            onChange={handleNumPeopleChange}
            placeholder='Número de personas'
          />
        </Form.Group>

        {pageName === 'productDetails' && (
          <Form.Group>
            <Select
              styles={customStyles}
              className={styles.controller}
              value={state.pickupTime}
              onChange={handlePickupTime}
              options={timeTableArray}
              placeholder='Hora de recogida'
            />
          </Form.Group>
        )}

        <Form.Group>
          <DatePicker
            className={styles.controller}
            selected={state.date}
            onChange={handleDateChange}
            includeDates={state.availableDates
              .map((dateString) => new Date(dateString))
              .filter((date) => filterDate(date, totalAvailableDates))}
            placeholderText={'Selecciona una fecha'}
          />
        </Form.Group>

        <Button
          onClick={submitForm}
          variant={'primary'}
          type='submit'
          className={styles.buttonController}
          disabled={
            pageName === 'productDetails'
              ? !enableSubmitButtonProductDetailsScreen
              : !enableSubmitButtonMainScreen
          }
        >
          Reservar
        </Button>
      </Form>
    </div>
  );
};
